@charset "utf-8";

///////////////////////////////////////////////////////////
//
//   siteTtl
//
///////////////////////////////////////////////////////////

//---------------------------------------------------------
// 必要ファイルインポート
//---------------------------------------------------------
@use "setting/variables" as v;
@use "foundation/mixins" as m;
@use "foundation/animation" as a;
@import "bootstrap/scss/bootstrap-utilities";

.siteTtl {
  a {
    display: flex;
    align-items: center;
    
    img {
      width: 12.6rem;
    }
    
    .siteTtl__sub {
      display: inline-block;
      font-size: m.clamp_size(14, 16);
      font-family: v.$fnt_en;
      color: #fff;
      padding: .3em .625em .25em;
      background-color: v.$clr_dred;
      margin-left: 2rem;
      line-height: 1;
      text-transform: uppercase;

      &--white {
        color: v.$clr_dred;
        background-color: #fff;
      }
    }
  }
}