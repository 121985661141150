@charset "utf-8";

///////////////////////////////////////////////////////////
//
//   footer
//
///////////////////////////////////////////////////////////

@use "../setting/variables" as v;
@use "../foundation/mixins" as m;
@use "../foundation/animation" as a;
@import "bootstrap/scss/bootstrap-utilities";

.footer__inner {
  background-color: v.$clr_dred;
}

.footer__contents {
  color: #fff;
  padding: 7rem 0 8.5rem;
  justify-content: space-between;
  @include media-breakpoint-down(lg) {
    justify-content: center;
  }
  @include media-breakpoint-down(md) {
    justify-content: flex-start;
  }
}

.footer__address {
  margin-top: 2.6rem !important;

  @include media-breakpoint-down(lg) {
    margin-top: -.6rem !important;
  }
  @include media-breakpoint-down(md) {
    margin-top: 2.6rem !important;
  }

  dt {
    font-size: m.clamp_size(12, 14);
    font-weight: bold;
  }
  
  dd {
    font-size: m.clamp_size(12, 14);
  }
}

.footer__nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  @include media-breakpoint-down(lg) {
    justify-content: center;
    margin-top: 3rem !important;
  }
  @include media-breakpoint-down(md) {
    justify-content: flex-start;
  }
  @include media-breakpoint-down(sm) {
    flex-direction: column;
  }
}

.footer__navList {
  @include media-breakpoint-down(md) {
    width: 50%;
  }
  @include media-breakpoint-down(sm) {
    width: 100%;
  }

  & + & {
    margin-left: 4.6rem;
    @include media-breakpoint-down(xl) {
      margin-left: 2.8rem;
    }
    @include media-breakpoint-down(lg) {
      margin-left: 4.6rem;
    }
    @include media-breakpoint-down(md) {
      margin-left: 0;
    }
    @include media-breakpoint-down(sm) {
      margin-top: 1.4rem;
    }
  }

  &:nth-of-type(3) {
    @include media-breakpoint-down(md) {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      margin-left: -2.4rem;
      margin-top: 2.4rem;

      .footer__navItem {
        margin-top: 0;
        margin-left: 2.4rem;
      }
    }
  }
}

.footer__navItem {
  & + & {
    margin-top: 2.8rem;
    @include media-breakpoint-down(sm) {
      margin-top: 1.4rem;
    }
  }

  .withIcon {
    position: relative;
    padding-left: 1em;
    display: inline-block;
    &::before {
      content: "\f0da";
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      font-size: m.clamp_size(12, 14);
      font-family: "Font Awesome 6 Free";
      color: #fff;
    }
  }
}

.footer__navLink {
  position: relative;
  color: #fff;
  padding-left: 1em;
  transition: color .2s a.$easeOut;
  @include m.linkColor(#fff);

  &::before {
    content: "\f0da";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    font-size: m.clamp_size(12, 14);
    font-family: "Font Awesome 6 Free";
  }

  &.outlook {
    padding-left: 0;
    padding-right: 1.2em;
    @include media-breakpoint-down(sm) {
      padding-right: 1.6em;
    }

    &::before {
      display: none;
    }
    
    .outlookIcon {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);

      &::before {
        content: "\f08e";
        font-size: 1.2rem;
        font-family: "Font Awesome 6 Free";
      }
    }
  }
}

.footer__navLink_txt {
  position: relative;
  display: inline-block;
  font-size: m.clamp_size(12, 14);

  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: .2rem;
    display: block;
    width: 100%;
    height: 1px;
    background-color: #fff;
    transition: opacity .2s a.$easeOut;
    transform: scaleX(0);
    transform-origin: 100% 0;
    transition: transform .6s cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  @include m.hover {
    &::after {
      transform: scaleX(1);
      transform-origin: 0 0;
    }
  }
}

.footer__navSubList {
  margin-left: 1rem;
}

.footer__navSubItem {
  @include media-breakpoint-down(sm) {
    width: 42%;
  }
}

.footer__navSubLink {
  position: relative;
  font-size: m.clamp_size(12, 14);
  color: #fff;
  padding-left: 2.14em;
  @include m.linkColor(#fff);

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    display: block;
    width: 2rem;
    height: .1rem;
    background-color: #fff;
  }
}

.footer__copyright {
  small {
    display: block;
    font-size: 1rem;
    color: #fff;
    text-align: center;
    padding: 1.4rem 0;
    background-color: v.$clr_dgray;
  }
}