@charset "utf-8";

///////////////////////////////////////////////////////////
//
//   btn
//
///////////////////////////////////////////////////////////

//---------------------------------------------------------
// 必要ファイルインポート
//---------------------------------------------------------
@use "setting/variables" as v;
@use "foundation/mixins" as m;
@use "foundation/animation" as a;
@import "bootstrap/scss/bootstrap-utilities";

.btn {
  position: relative;
  display: inline-block;
  font-size: 1.4rem;
  font-weight: 600;
  color: #fff;
  text-align: center;
  width: 26.4rem;
  max-width: 100%;
  padding: .9em 1em;
  border-radius: 27px;
  border: 2px solid transparent;
  background-color: v.$clr_dred;
  transition: all .3s a.$ease;
  @include m.linkColor(#fff);

  @include m.hover {
    color: v.$clr_dred;
    border: 2px solid v.$clr_dred;
    background-color: transparent;
  }

  &--icon {
    &::after {
      position: absolute;
      top: 50%;
      right: 3em;
      transform: translateY(-50%);
    }
    
    @include m.hover {
      &::after {
        color: v.$clr_dred;
      }
    }

    &--outlook {
      width: 24.2rem;
      &::after {
        content: "\f08e";
        font-size: 1.2rem;
        font-family: "Font Awesome 6 Free";
        @include media-breakpoint-down(xl) {
          right: 2em;
        }
        @include media-breakpoint-down(lg) {
          right: 3em;
        }
      }
    }
  }
}