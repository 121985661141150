@charset "utf-8";

///////////////////////////////////////////////////////////
//
//   aside
//
///////////////////////////////////////////////////////////

@use "../setting/variables" as v;
@use "../foundation/mixins" as m;
@use "../foundation/animation" as a;
@import "bootstrap/scss/bootstrap-utilities";

.aside {
  width: 30%;
  @include media-breakpoint-down(lg) {
    width: 100%;
    margin-top: 7rem;
  }
}

.aside__ttl {
  font-size: 2.2rem;
  font-weight: bold;
  font-family: v.$fnt_mincho;
  color: v.$clr_dred;
  line-height: 1;
  margin-bottom: 1.5rem;
}

.aside__archiveList {
  border-top: 1px solid #e3e3e3;

  li {
    position: relative;
    padding: 1.7rem 0 1.7rem 2rem;
    border-bottom: 1px solid #e3e3e3;
    
    &::before {
      content: "\f0da";
      font-size: 1.6rem;
      font-family: "Font Awesome 6 Free";
      color: v.$clr_dred;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }

    a {
      display: inline-block;
      font-size: m.clamp_size(12, 16);
      padding: .2em 0;
      transition: color .3s a.$ease;
      
      @include m.hover {
        color: v.$clr_dred;
      }
    }
  }
}