@charset "utf-8";

///////////////////////////////////////////////////////////
//
//   wrapper
//
///////////////////////////////////////////////////////////

//---------------------------------------------------------
// 必要ファイルインポート
//---------------------------------------------------------
@use "setting/variables" as v;
@use "foundation/mixins" as m;
@use "foundation/animation" as a;
@import "bootstrap/scss/bootstrap-utilities";

.wrapper {
  margin-top: 1.6rem;
  margin-bottom: m.clamp_size(120, 130);
}