
@charset "utf-8";

///////////////////////////////////////////////////////////
//
//   sectionTtl
//
///////////////////////////////////////////////////////////

@use "../setting/variables" as v;
@use "../foundation/mixins" as m;
@use "../foundation/animation" as a;
@import "bootstrap/scss/bootstrap-utilities";

.section1thTtl {
  display: flex;
  align-items: center;
  color: v.$clr_dred;

  .en {
    display: block;
    font-size: 2.5rem;
    font-family: v.$fnt_en;
    text-transform: uppercase;
  }

  .ja {
    display: block;
    font-size: 1.2rem;
    font-weight: bold;
    margin-left: 2rem;
    @include media-breakpoint-down(lg) {
      margin-left: 1rem;
    }
    @include media-breakpoint-down(md) {
      margin-left: 1.5rem;
    }
  }
}

.section2thTtl {
  font-size: 2.1rem;
  font-weight: bold;
  color: v.$clr_dred;
  padding-left: 0.35em;
  margin-bottom: 1em;
  background: transparent;
  border-left: solid 7px v.$clr_dred;
}

.section3thTtl {
  font-size: 2rem;
  font-weight: bold;
  color: v.$clr_dred;
}