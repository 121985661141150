@charset "utf-8";

///////////////////////////////////////////////////////////
//
//   breadcrumb
//
///////////////////////////////////////////////////////////

//---------------------------------------------------------
// 必要ファイルインポート
//---------------------------------------------------------
@use "setting/variables" as v;
@use "foundation/mixins" as m;
@use "foundation/animation" as a;
@import "bootstrap/scss/bootstrap-utilities";

.breadcrumb {

  span[property="itemListElement"] {
    position: relative;
    display: inline-block;
  
    &:not(:first-of-type) {
      margin-left: 3rem;
  
      &::before {
        content: '';
        width: 10px;
        height: 10px;
        border-top: solid 1px v.$clr_dred;
        border-right: solid 1px v.$clr_dred;
        position: absolute;
        top: 50%;
        left: -2.2rem;
        transform: translateY(-50%) rotate(45deg);
      }
    }
  }
  
  span[property="name"] {
    position: relative;
    display: inline-block;
    padding: .2rem 0;
    font-size: 1.2rem;
    color: v.$clr_dred;
  
    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: .4rem;
      display: block;
      width: 100%;
      height: 1px;
      background-color: v.$clr_dred;
      transition: opacity .2s a.$easeOut;
      opacity: 0;
    }
  }
  
  a[property="item"] {
    @include m.hover {
      span[property="name"] {
        &::after {
          opacity: 1;
        }
      }
    }
  }
}