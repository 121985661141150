@charset "utf-8";
//a
// variables
@use "setting/variables" as v;
@use "foundation/mixins" as m;
@use "foundation/animation" as a;
//
// frameworks
// @use 'normalize'

// utilities
// @use 'utility/grid'
// @use 'utility/font'
// @use 'utility/icon'
@use 'utility/margin';

// @use 'utility/loading'

// project
// @use 'project/layout/header';
// @use 'project/layout/footer';
// @use 'project/layout/section';
// @use 'project/layout/wraper';
// @use 'project/layout/inner';

// layout
@use 'layout/header';
@use 'layout/footer';
@use 'layout/aside';
@use 'layout/wrapper';
@use 'layout/section';

// project
@use 'project/siteTtl';
@use 'project/sectionTtl';
@use 'project/pageTopBtn';
@use 'project/subfv';
@use 'project/breadcrumb';
@use 'project/newsTabList';
@use 'project/pagenavi';

// component
@use 'component/btn';

// bootstrap 読み込み
// @import "bootstrap/scss/functions"

$grid-gutter-width: 5rem;
$spacer: 5rem;
// $spacers: (  0: 0, 1: $spacer * .25,  2: $spacer * .5,  3: $spacer,  4: $spacer * 1.5,  5: $spacer * 3,)

// その他bsのvariables書き換えはここで

// @import "bootstrap/scss/variables"
// @import "bootstrap/scss/mixins"
// @import "bootstrap/scss/root"
// @import "bootstrap/scss/reboot"
// @import "bootstrap/scss/type"
// @import "bootstrap/scss/images"
// @import "bootstrap/scss/containers"
@import "bootstrap/scss/bootstrap-reboot";
@import "bootstrap/scss/bootstrap-grid";
@import "bootstrap/scss/bootstrap-utilities";

// fontawesome 読み込み
$fa-font-path: "../font";

// その他faのvariables書き換えはここで
@import "../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-free/scss/solid";

* {
  min-height: 0vw; /* Safari clamp関数対策 */
}

html,
body {
  color: v.$clr_black;
  font-weight: v.$fnt_weight_default;
  font-size: 10px;
  line-height: v.$fnt_lh_default;
  font-family: v.$fnt_ja;
}

div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
// li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  box-sizing: border-box;
  font-size: v.$fnt_size_default;
  font-weight: v.$fnt_weight_default;
}

a {
  text-decoration: none;
  cursor: pointer;
  @include m.linkColor(v.$clr_black);
}

img {
  max-width: 100%;
  vertical-align: bottom;
}

//---------------------------------------------------------
// 共通パーツ
//---------------------------------------------------------
ul,
dl,
ol,
dl,
dt,
dd,
h1,
h2,
h3,
h4,
h5,
figure,
p {
  margin: 0;
  padding: 0;
}

ol, ul {
  list-style: none;
}

strong {
  font-weight: bold;
}

.text-center {
  text-align: center;
}

//フォームリセット
input,
button,
select,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  border: none;
  border-radius: 0;
  font: inherit;
  outline: none;

  &::placeholder {
    color: #c4c4c4;
  }
}

textarea {
  resize: vertical;
}

input[type="checkbox"],
input[type="radio"] {
  appearance: none;
  display: none;
}

input[type="submit"],
input[type="button"],
label,
button,
select {
  cursor: pointer;
}

select::-ms-expand {
  display: none;
}