@charset "utf-8";

///////////////////////////////////////////////////////////
//
//   pageTopBtn
//
///////////////////////////////////////////////////////////

@use "../setting/variables" as v;
@use "../foundation/mixins" as m;
@use "../foundation/animation" as a;
@import "bootstrap/scss/bootstrap-utilities";

.pageTopBtn {
  height: 4rem;
  background-color: v.$clr_lgray;
  
  a {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    
    &::before {
      content: "\f077";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 2.4rem;
      font-family: "Font Awesome 6 Free";
      color: v.$clr_dred;
    }
  }
}