@charset "utf-8";

///////////////////////////////////////////////////////////
//
//   subfv
//
///////////////////////////////////////////////////////////

//---------------------------------------------------------
// 必要ファイルインポート
//---------------------------------------------------------
@use "setting/variables" as v;
@use "foundation/mixins" as m;
@use "foundation/animation" as a;
@import "bootstrap/scss/bootstrap-utilities";

.subfv {
  display: grid;
  place-items: center;
  height: m.clamp_size(170, 277);
  margin-top: 7.2rem;
  background-color: v.$clr_wsmoke;
}

.subfv__ttl {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 3.6rem;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    width: 1px;
    height: m.clamp_size(25, 30);
    background-color: v.$clr_dred;
  }

  .en {
    display: inline-block;
    font-size: m.clamp_size(46, 61);
    font-family: v.$fnt_en;
    color: v.$clr_dred;
    text-transform: uppercase;
  }

  .ja {
    display: inline-block;
    font-size: m.clamp_size(13, 16);
    font-weight: bold;
    color: v.$clr_dred;
    margin-top: .5em;
  }
}