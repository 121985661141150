@charset "utf-8";

@use "../foundation/mixins" as m;

///////////////////////////////////////////////////////////
//
//   変数定義用SASS
//
///////////////////////////////////////////////////////////

//---------------------------------------------------------
// ブレイクポイント
//---------------------------------------------------------

$breakpoint_up: (
  sm: "screen and (min-width: 576px)",
  md: "screen and (min-width: 768px)",
  lg: "screen and (min-width: 992px)",
  xl: "screen and (min-width: 1200px)",
  xxl: "screen and (min-width: 1400px)"
);

$breakpoint_down: (
  sm: "screen and (max-width: 575.98px)",
  md: "screen and (max-width: 767.98px)",
  lg: "screen and (max-width: 991.98px)",
  xl: "screen and (max-width: 1199.98px)",
  xxl: "screen and (max-width: 1399.98px)"
);

//---------------------------------------------------------
// レイアウト
//---------------------------------------------------------

// コンテナ幅
// $container_width: ( fluid:100%, md:720px, lg:960px, xl:1140px, xxl:1320px )

// 余白
// $gap: 5rem // 30px

//---------------------------------------------------------
// カラー
//---------------------------------------------------------
$clr_black: #000000;
$clr_dgray: #2c2c2c;
$clr_gray: #8d8d8d;
$clr_lgray: #dbdbdb;
$clr_wsmoke: #f7f7f7;
$clr_dred: #7f1a3e;
$clr_green: #96d05c;
$clr_blue: #5cbad0;
$clr_red: #9a0047;
$clr_purple: #845cd0;

// $clr_orange: #FAAC1A;
// $clr_lightgreen: #f4f8f7;
// $clr_lime: #9bd8c3;
// $clr_red: #C46868;
// $clr_beni: #c75151;

//---------------------------------------------------------
// デフォルトフォント設定
//---------------------------------------------------------
// フォントサイズ
$fnt_size_default: m.clamp_size(12, 16);

// 太さ
$fnt_weight_default: 400;

// 行間
$fnt_lh_default: 1.86;

// フォントファミリー
@import url('https://fonts.googleapis.com/css2?family=Archivo+Narrow:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500&display=swap');

$fnt_ja: 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'Noto Sans JP', sans-serif;
$fnt_en:  'Archivo Narrow', sans-serif;
$fnt_mincho: "Shippori Mincho", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;