@charset "utf-8";

///////////////////////////////////////////////////////////
//
//   section
//
///////////////////////////////////////////////////////////

//---------------------------------------------------------
// 必要ファイルインポート
//---------------------------------------------------------
@use "setting/variables" as v;
@use "foundation/mixins" as m;
@use "foundation/animation" as a;
@import "bootstrap/scss/bootstrap-utilities";

.section {
  margin-top: 10rem;

  &--top {
    margin-top: 4.8rem;
    @include media-breakpoint-down(md) {
      margin-top: 5rem;
    }
  }
}