@charset "utf-8";

///////////////////////////////////////////////////////////
//
//   newsTabList
//
///////////////////////////////////////////////////////////

//---------------------------------------------------------
// 必要ファイルインポート
//---------------------------------------------------------
@use "setting/variables" as v;
@use "foundation/mixins" as m;
@use "foundation/animation" as a;
@import "bootstrap/scss/bootstrap-utilities";

.newsTabList {
  display: flex;
  border-bottom: 2px solid v.$clr_dred;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display:none;
  }
}

.newsTabList__item {
  font-size: m.clamp_size(12, 14);
  color: #fff;
  text-align: center;
  padding: .38em 1.15em;
  background-color: v.$clr_gray;
  cursor: pointer;
  white-space: nowrap;
  @include m.linkColor(#fff);

  & + & {
    margin-left: 1rem;
  }

  &.is-btn-active {
    background-color: v.$clr_dred;
    pointer-events: none;
  }
}

/* newsTabList__contents */
.newsTabList__contents {
  display: none;

  &.is-contents-active {
    display: block;
  }
}

.newsTabList__contentsResultNum {
  font-size: 1.2rem;
  margin-bottom: 1.6rem;
  .all,
  .result {
    font-size: 1.2rem;
  }
}

/* newsList */
.newsList__item {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e3e3e3;
  @include media-breakpoint-down(md) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.newsList__link {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: m.clamp_size(17, 21) 0;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    display: block;
    width: 100%;
    height: 1px;
    background-color: v.$clr_dred;
    transform: scaleX(0);
    transform-origin: 100% 0;
    transition: transform .6s cubic-bezier(0.215, 0.61, 0.355, 1);
    z-index: 1;
  }

  @include m.hover {
    &::after {
      transform: scaleX(1);
      transform-origin: 0 0;
    }

    .newsList__ttl {
      color: v.$clr_dred;
    }
  }
}

.newsList__head {
  display: flex;
  align-items: center;
}

.newsList__date {
  margin-right: m.clamp_size(10, 30);
  time {
    font-size: m.clamp_size(12, 14);
  }
}

.newsList__cateList {
  display: flex;
  align-items: center;
}

.newsList__cateItem {
  min-width: 12rem;
  text-align: center;
  @include media-breakpoint-down(md) {
    text-align: left;
  }

  &:not(:first-child) {
    margin-left: 1rem;
  }

  &--single {
    min-width: 0;
  }
}

.newsList__cateName,
.newsList__cateLink {
  display: inline-block;
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
  padding: 0.28em 1.5em;
  border-radius: 15px;
  transition: opacity .4s a.$ease;
  @include m.linkColor(#fff);
  @include media-breakpoint-down(md) {
    padding: .2em 1.5em;
  }
  
  &--blue {
    background-color: v.$clr_blue;
  }
  
  &--green {
    background-color: v.$clr_green;
  }
  
  &--red {
    background-color: v.$clr_red;
  }
  
  &--purple {
    background-color: v.$clr_purple;
  }
}

.newsList__ttl {
  margin-left: m.clamp_size(16, 24);
  transition: color .4s a.$ease;
  @include media-breakpoint-down(md) {
    margin-top: .8rem;
    margin-left: 0;
  }
}