@charset "utf-8";

///////////////////////////////////////////////////////////
//
//   header
//
///////////////////////////////////////////////////////////

@use "../setting/variables" as v;
@use "../foundation/mixins" as m;
@use "../foundation/animation" as a;
@import "bootstrap/scss/bootstrap-utilities";

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 7.2rem;
  z-index: 25;
  background-color: #fff;
}

.header__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 7.2rem;
}

.header__btn {
  position: relative;
  display: inline-block;
  width: 7.2rem;
  height: 100%;
  background-color: v.$clr_dred;

  @include m.hover {
    .header__btn_inline {
      &:nth-child(1) {
        top: 3.4rem;
      }
      &:nth-child(2) {
        top: 3.4rem;
      }
    }
  }
}

.header__btn_inline {
  display: inline-block;
  position: absolute;
  width: 3.7rem;
  height: .2rem;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  border-radius: 1px;
  transition: all .3s a.$ease;

  &:nth-child(1) {
    top: 2.9rem;
  }
  &:nth-child(2) {
    top: 3.9rem;
  }
}

/* ヘッダーメニュー
---------------------------------------------------------- */
/* メニュー展開時 背景固定 */
body.is-open {
  height: 100%;
  overflow: hidden;
}

.header__nav {
  position: fixed;
  top: 0;
  right: 0;
  width: 40vw;
  max-width: 50rem;
  height: 100vh;
  height: 100dvh;
  background-color: v.$clr_dred;
  transform: translateX(100%);
  transition: transform .5s a.$easeInOutCubic;
  pointer-events: none;
  overflow: scroll;
  z-index: 100;
  @include media-breakpoint-down(lg) {
    width: 50vw;
  }
  @include media-breakpoint-down(md) {
    width: 70vw;
  }
  @include media-breakpoint-down(sm) {
    width: 100vw;
  }

  &.is-open {
    transform: translateX(0);
    pointer-events: auto;
  }
}

.header__navList {
  padding: 7.2rem;
}

.header__navItem {
  color: #fff;

  & + & {
    margin-top: 2.8rem;
  }

  & > span {
    font-size: m.clamp_size(14, 16);
  }

  .withIcon {
    position: relative;
    padding-left: 1em;
    display: inline-block;
    &::before {
      content: "\f0da";
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      font-size: m.clamp_size(14, 16);
      font-family: "Font Awesome 6 Free";
      color: #fff;
    }
  }
}

.header__navLink {
  position: relative;
  color: #fff;
  padding-left: 1em;
  transition: color .2s a.$easeOut;
  @include m.linkColor(#fff);

  &::before {
    content: "\f0da";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    font-size: m.clamp_size(14, 16);
    font-family: "Font Awesome 6 Free";
  }

  &.outlook {
    padding-left: 0;
    padding-right: 1.2em;

    &::before {
      display: none;
    }
    
    .outlookIcon {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);

      &::before {
        content: "\f08e";
        font-size: 1.2rem;
        font-family: "Font Awesome 6 Free";
      }
    }
  }
}

.header__navLink_txt {
  position: relative;
  display: inline-block;
  font-size: m.clamp_size(14, 16);

  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: .2rem;
    display: block;
    width: 100%;
    height: 1px;
    background-color: #fff;
    transition: opacity .2s a.$easeOut;
    transform: scaleX(0);
    transform-origin: 100% 0;
    transition: transform .6s cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  @include m.hover {
    &::after {
      transform: scaleX(1);
      transform-origin: 0 0;
    }
  }
}

.header__navSubList {
  margin-left: 1rem;
}

.header__navSubLink {
  position: relative;
  color: #fff;
  padding-left: 2.14em;
  @include m.linkColor(#fff);

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    display: block;
    width: 2rem;
    height: .1rem;
    background-color: #fff;
  }
}

.header__closeBtn {
  position: absolute;
  top: 0;
  right: 2rem;
  display: inline-block;
  width: 7.2rem;
  height: 100%;
  opacity: 0;
  z-index: 150;

  &.is-open {
    animation-name: fadeCloseBtn;
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
  }

  @keyframes fadeCloseBtn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .header__btn_inline {
    display: inline-block;
    position: absolute;
    width: 3.7rem;
    height: .2rem;
    background-color: #fff;
    border-radius: 1px;

    &:nth-child(1) {
      top: 3rem;
      left: 1.6rem;
      transform: translateY(6px) rotate(-45deg);
    }
    &:nth-child(2) {
      top: 4.2rem;
      left: 1.6rem;
      transform: translateY(-6px) rotate(45deg);
    }
  }
}

.header__bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: 100dvh;
  transform: translateX(100%);
  background-color: rgba(44,44,44, 0.8);
  z-index: 50;
  transition: transform .5s a.$easeInOutCubic;
  @include media-breakpoint-down(sm) {
    display: none;
  }

  &.is-open {
    transform: translateX(0);
  }
}

/* slideInMenu */
.slideInMenu {
  display: inline-block;
  transform: translate3d(35px, 0, 0);
  opacity: 0;

  &.is-open {
    animation-name: slideInMenu;
    animation-duration: .63s;
    animation-fill-mode: forwards;
  }
}

.header__navItem {
  &:nth-child(1) { .slideInMenu { animation-delay: .2s; } }
  &:nth-child(2) { .slideInMenu { animation-delay: .27s; } }
  &:nth-child(3) { .slideInMenu { animation-delay: .34s; } }
  &:nth-child(4) { .slideInMenu { animation-delay: .69s; } }
  &:nth-child(5) { .slideInMenu { animation-delay: .76s; } }
  &:nth-child(6) { .slideInMenu { animation-delay: .83s; } }
}

.header__navSubItem {
  &:nth-child(1) { .slideInMenu { animation-delay: .41s; } }
  &:nth-child(2) { .slideInMenu { animation-delay: .48s; } }
  &:nth-child(3) { .slideInMenu { animation-delay: .55s; } }
  &:nth-child(4) { .slideInMenu { animation-delay: .62s; } }
}

@keyframes slideInMenu {
  0% {
      opacity: 0;
      transform: translate3d(20px, 0, 0);
  }
  100% {
      opacity: 1;
      transform: translate3d(0, 0, 0);
  }
}
