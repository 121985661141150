@charset "utf-8";

///////////////////////////////////////////////////////////
//
//   pager
//
///////////////////////////////////////////////////////////

//---------------------------------------------------------
// 必要ファイルインポート
//---------------------------------------------------------
@use "setting/variables" as v;
@use "foundation/mixins" as m;
@use "foundation/animation" as a;
@import "bootstrap/scss/bootstrap-utilities";

.wp-pagenavi {
  display: flex;
  align-items: center;
  margin-top: m.clamp_size(50, 53);
  margin-left: -.5rem;

  a,
  span {
    padding: 0 !important;
  }

  .page,
  .current,
  .previouspostslink,
  .nextpostslink,
  .extend {
    margin: 0 .5rem;
  }
  
  .page,
  .current,
  .previouspostslink,
  .nextpostslink {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.4rem;
    font-weight: 600;
    width: 5.4rem;
    height: 5.4rem;
    color: #fff;
  }

  .page,
  .previouspostslink,
  .nextpostslink {
    position: relative;
    border: 2px solid transparent;
    background-color: #555555;
    transition: .3s cubic-bezier(.77,0,.18,1);

    @include m.hover {
      color: v.$clr_dred;
      border: 2px solid v.$clr_dred !important;
      background-color: #fff;
    }
  }

  span.current {
    color: #fff;
    background-color: v.$clr_dred;
    border: 2px solid v.$clr_dred !important;
    pointer-events: none;
  }

  .previouspostslink,
  .nextpostslink {
    &::after {
      content: "";
      display: inline-block;
      width: .8rem;
      height: .8rem;
      border-top: 2px solid #fff;
      border-right: 2px solid #fff;
      margin-right: .1rem;
      vertical-align: middle;
      transition: .3s cubic-bezier(.77,0,.18,1);
      transition-property: border-color;
    }

    @include m.hover {
      &::after {
        border-color: v.$clr_dred;
        transition-duration: .2s;
      }
    }
  }

  .previouspostslink {
    &::after {
      transform: rotate(225deg);
      margin-left: .2em;
    }
  }

  .nextpostslink {
    &::after {
      transform: rotate(45deg);
      margin-right: .2em;
    }
  }

  .first,
  .last {
    display: none;
  }

  // .extend {
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   font-weight: 600;
  //   width: m.clamp_size(15, 17);
  //   height: m.clamp_size(15, 17);
  //   border-radius: 50%;
  //   border: none;

  //   &:last-of-type {
  //     display: none;
  //   }
  // }
}